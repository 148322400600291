import React from 'react';
import { graphql, navigate } from 'gatsby'
import Footer from '../../components/Footer'; 
import Header from '../../components/Header'; 
import Layout from '../../components/Layout';
import BlogPost from '../../components/BlogPost';

  import ContentfulComponentSeo from '../../ContentfulComponents/ContentfulComponentSeo';
  

export const query = graphql`
query {
  contentfulPost (node_locale: {eq: "en-US"}, contentful_id: {eq: "3P6sZT0snty8ryiHx7HhS2"}) {
    id
    title
    subtitle
    author
    datePosted
    timeToRead
    slug
    blogTags
    relatedPosts {
      id
      title
      subtitle
      slug
      author
      timeToRead
      datePosted
      blogTags
      image{
        title
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          quality: 75
        )
      }
      blogContent {
        raw
      }
  } 
    image{
      title
      gatsbyImageData(
        placeholder: DOMINANT_COLOR
        quality: 75
      )
    }  
    blogContent {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            quality: 75
          )
        }
      }
    }
  }
   
 

    contentfulComponentSeo0: contentfulComponentSeo (contentful_id: {eq: "2b2lACKABGqrhRt1623vBT"}) {
      
      title
      siteUrl
      id
      description {
        description
      }
      default
      image {
        fixed(width: 800) {
          src
          srcSet
          height
          width
        }
      }

      internal {
        type
      }    
    }
  




}
`
const Post = (props) => {
  const { contentIds, ...context } = props.pageContext
  const extraData = {
  "data": [
    {
      "node": {
        "sys": {
          "id": "7sLstJXeao55fMymbO7V7s"
        },
        "available": true,
        "name": "academic-tutorials",
        "menuText": "1:1 subject support",
        "order": 3,
        "title": "1:1 subject support",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "480jXQp2ZpVYOS0AunDTEE"
          },
          "available": true,
          "name": "university-preparation",
          "menuText": "University preparation",
          "order": 2,
          "title": "University Preparation (Root menu)",
          "pathPrefix": null,
          "contentful_id": "480jXQp2ZpVYOS0AunDTEE",
          "id": "480jXQp2ZpVYOS0AunDTEE",
          "parentPage": null
        },
        "contentful_id": "7sLstJXeao55fMymbO7V7s",
        "id": "7sLstJXeao55fMymbO7V7s"
      }
    },
    {
      "node": {
        "sys": {
          "id": "656XiQKsEq1wqc270Bi7DD"
        },
        "available": true,
        "name": "university-guidance",
        "menuText": "University guidance programs",
        "order": 1,
        "title": "University guidance!",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "7GzCWBkn7zE44ESpIsA2Un"
          },
          "available": true,
          "name": "uniguidance",
          "menuText": "University guidance",
          "order": 1,
          "title": "University guidance",
          "pathPrefix": null,
          "contentful_id": "7GzCWBkn7zE44ESpIsA2Un",
          "id": "7GzCWBkn7zE44ESpIsA2Un",
          "parentPage": null
        },
        "contentful_id": "656XiQKsEq1wqc270Bi7DD",
        "id": "656XiQKsEq1wqc270Bi7DD"
      }
    },
    {
      "node": {
        "sys": {
          "id": "31cmAwGY8QOBMT8RrL5fNE"
        },
        "parentPage": null,
        "available": true,
        "name": "terms",
        "menuText": "T&Cs",
        "order": null,
        "title": "Terms and Conditions",
        "pathPrefix": null,
        "contentful_id": "31cmAwGY8QOBMT8RrL5fNE",
        "id": "31cmAwGY8QOBMT8RrL5fNE"
      }
    },
    {
      "node": {
        "sys": {
          "id": "2OwqwVsqtq7th1xMJbXEVt"
        },
        "available": true,
        "name": "personal-statement-support",
        "menuText": "Personal statement support",
        "order": 4,
        "title": "Personal statement support",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "7GzCWBkn7zE44ESpIsA2Un"
          },
          "available": true,
          "name": "uniguidance",
          "menuText": "University guidance",
          "order": 1,
          "title": "University guidance",
          "pathPrefix": null,
          "contentful_id": "7GzCWBkn7zE44ESpIsA2Un",
          "id": "7GzCWBkn7zE44ESpIsA2Un",
          "parentPage": null
        },
        "contentful_id": "2OwqwVsqtq7th1xMJbXEVt",
        "id": "2OwqwVsqtq7th1xMJbXEVt"
      }
    },
    {
      "node": {
        "sys": {
          "id": "2OmoAgOio6uriFhHVQgySA"
        },
        "available": true,
        "name": "admissions-test-preparation",
        "menuText": "Admissions test preparation",
        "order": 5,
        "title": "Admissions test preparation",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "7GzCWBkn7zE44ESpIsA2Un"
          },
          "available": true,
          "name": "uniguidance",
          "menuText": "University guidance",
          "order": 1,
          "title": "University guidance",
          "pathPrefix": null,
          "contentful_id": "7GzCWBkn7zE44ESpIsA2Un",
          "id": "7GzCWBkn7zE44ESpIsA2Un",
          "parentPage": null
        },
        "contentful_id": "2OmoAgOio6uriFhHVQgySA",
        "id": "2OmoAgOio6uriFhHVQgySA"
      }
    },
    {
      "node": {
        "sys": {
          "id": "5Mw8aOvJfTH5S8HZdWbDJm"
        },
        "available": true,
        "name": "interview-preparation",
        "menuText": "Interview preparation",
        "order": 6,
        "title": "Interview preparation",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "7GzCWBkn7zE44ESpIsA2Un"
          },
          "available": true,
          "name": "uniguidance",
          "menuText": "University guidance",
          "order": 1,
          "title": "University guidance",
          "pathPrefix": null,
          "contentful_id": "7GzCWBkn7zE44ESpIsA2Un",
          "id": "7GzCWBkn7zE44ESpIsA2Un",
          "parentPage": null
        },
        "contentful_id": "5Mw8aOvJfTH5S8HZdWbDJm",
        "id": "5Mw8aOvJfTH5S8HZdWbDJm"
      }
    },
    {
      "node": {
        "sys": {
          "id": "42pogCWvorVmuS0easVTGH"
        },
        "available": true,
        "name": "university-study-skills",
        "menuText": "University study skills",
        "order": 7,
        "title": "University study skills",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "7GzCWBkn7zE44ESpIsA2Un"
          },
          "available": true,
          "name": "uniguidance",
          "menuText": "University guidance",
          "order": 1,
          "title": "University guidance",
          "pathPrefix": null,
          "contentful_id": "7GzCWBkn7zE44ESpIsA2Un",
          "id": "7GzCWBkn7zE44ESpIsA2Un",
          "parentPage": null
        },
        "contentful_id": "42pogCWvorVmuS0easVTGH",
        "id": "42pogCWvorVmuS0easVTGH"
      }
    },
    {
      "node": {
        "sys": {
          "id": "4bcISIYuG8SJbPZp5W9Mdq"
        },
        "available": true,
        "name": "undecided",
        "menuText": "Helping you to choose",
        "order": 3,
        "title": "undecided",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "7GzCWBkn7zE44ESpIsA2Un"
          },
          "available": true,
          "name": "uniguidance",
          "menuText": "University guidance",
          "order": 1,
          "title": "University guidance",
          "pathPrefix": null,
          "contentful_id": "7GzCWBkn7zE44ESpIsA2Un",
          "id": "7GzCWBkn7zE44ESpIsA2Un",
          "parentPage": null
        },
        "contentful_id": "4bcISIYuG8SJbPZp5W9Mdq",
        "id": "4bcISIYuG8SJbPZp5W9Mdq"
      }
    },
    {
      "node": {
        "sys": {
          "id": "6J0B3cSmFMTYlvNYDjnCif"
        },
        "available": true,
        "name": "pricing",
        "menuText": "Pricing",
        "order": 5,
        "title": "Pricing",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "480jXQp2ZpVYOS0AunDTEE"
          },
          "available": true,
          "name": "university-preparation",
          "menuText": "University preparation",
          "order": 2,
          "title": "University Preparation (Root menu)",
          "pathPrefix": null,
          "contentful_id": "480jXQp2ZpVYOS0AunDTEE",
          "id": "480jXQp2ZpVYOS0AunDTEE",
          "parentPage": null
        },
        "contentful_id": "6J0B3cSmFMTYlvNYDjnCif",
        "id": "6J0B3cSmFMTYlvNYDjnCif"
      }
    },
    {
      "node": {
        "sys": {
          "id": "6cQyMr3QFtPXCPb7DpQEDx"
        },
        "available": true,
        "name": "how-melio-works",
        "menuText": "How Melio works",
        "order": 8,
        "title": "How Melio works",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "7GzCWBkn7zE44ESpIsA2Un"
          },
          "available": true,
          "name": "uniguidance",
          "menuText": "University guidance",
          "order": 1,
          "title": "University guidance",
          "pathPrefix": null,
          "contentful_id": "7GzCWBkn7zE44ESpIsA2Un",
          "id": "7GzCWBkn7zE44ESpIsA2Un",
          "parentPage": null
        },
        "contentful_id": "6cQyMr3QFtPXCPb7DpQEDx",
        "id": "6cQyMr3QFtPXCPb7DpQEDx"
      }
    },
    {
      "node": {
        "sys": {
          "id": "5MaldIKFh97uJRZqLww3pp"
        },
        "available": true,
        "name": "study-in-the-uk",
        "menuText": "Study in the UK ",
        "order": 1,
        "title": "Study in the UK ",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "4tbQ50DrlyOpEqHTYWZO8V"
          },
          "available": true,
          "name": "where-to-study",
          "menuText": "Where to study",
          "order": 3,
          "title": "Where to study (Root menu)",
          "pathPrefix": null,
          "contentful_id": "4tbQ50DrlyOpEqHTYWZO8V",
          "id": "4tbQ50DrlyOpEqHTYWZO8V",
          "parentPage": null
        },
        "contentful_id": "5MaldIKFh97uJRZqLww3pp",
        "id": "5MaldIKFh97uJRZqLww3pp"
      }
    },
    {
      "node": {
        "sys": {
          "id": "3QeUfxW7xd8hFiLlMGcQ1n"
        },
        "parentPage": null,
        "available": true,
        "name": "university-guidance-for-schools-pdf",
        "menuText": "University guidance for schools brochure",
        "order": null,
        "title": "University pdf for schools ",
        "pathPrefix": null,
        "contentful_id": "3QeUfxW7xd8hFiLlMGcQ1n",
        "id": "3QeUfxW7xd8hFiLlMGcQ1n"
      }
    },
    {
      "node": {
        "sys": {
          "id": "2XUldSCLrZ1Oa2DasDHMUt"
        },
        "parentPage": null,
        "available": true,
        "name": "university-guidance-pdf",
        "menuText": "University guidance brochure",
        "order": null,
        "title": "University pdf",
        "pathPrefix": null,
        "contentful_id": "2XUldSCLrZ1Oa2DasDHMUt",
        "id": "2XUldSCLrZ1Oa2DasDHMUt"
      }
    },
    {
      "node": {
        "sys": {
          "id": "blPYmnQ9O4RU9xKbWaEwL"
        },
        "parentPage": null,
        "available": true,
        "name": "test",
        "menuText": "test",
        "order": null,
        "title": "Test(DO NOT DELETE)",
        "pathPrefix": null,
        "contentful_id": "blPYmnQ9O4RU9xKbWaEwL",
        "id": "blPYmnQ9O4RU9xKbWaEwL"
      }
    },
    {
      "node": {
        "sys": {
          "id": "53FduIG6L8slARkfgOSYMF"
        },
        "available": true,
        "name": "premium-university-guidance",
        "menuText": "Premium guidance programs",
        "order": 2,
        "title": "Premium university guidance",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "7GzCWBkn7zE44ESpIsA2Un"
          },
          "available": true,
          "name": "uniguidance",
          "menuText": "University guidance",
          "order": 1,
          "title": "University guidance",
          "pathPrefix": null,
          "contentful_id": "7GzCWBkn7zE44ESpIsA2Un",
          "id": "7GzCWBkn7zE44ESpIsA2Un",
          "parentPage": null
        },
        "contentful_id": "53FduIG6L8slARkfgOSYMF",
        "id": "53FduIG6L8slARkfgOSYMF"
      }
    },
    {
      "node": {
        "sys": {
          "id": "4uAgLmTjZ3G65y2VgN6ATB"
        },
        "available": true,
        "name": "about-us",
        "menuText": "About",
        "order": 1,
        "title": "About Us",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "3pk6v4cfcMxChmlAmqsRvr"
          },
          "available": true,
          "name": "about",
          "menuText": "About",
          "order": 5,
          "title": "About Melio",
          "pathPrefix": null,
          "contentful_id": "3pk6v4cfcMxChmlAmqsRvr",
          "id": "3pk6v4cfcMxChmlAmqsRvr",
          "parentPage": null
        },
        "contentful_id": "4uAgLmTjZ3G65y2VgN6ATB",
        "id": "4uAgLmTjZ3G65y2VgN6ATB"
      }
    },
    {
      "node": {
        "sys": {
          "id": "3PunlTsT2l07jGVj4Z4Tky"
        },
        "parentPage": null,
        "available": true,
        "name": "index",
        "menuText": "Home",
        "order": 1,
        "title": "Melio Education - Online Learning with Oxford and Cambridge tutors",
        "pathPrefix": null,
        "contentful_id": "3PunlTsT2l07jGVj4Z4Tky",
        "id": "3PunlTsT2l07jGVj4Z4Tky"
      }
    },
    {
      "node": {
        "sys": {
          "id": "1bYTwntNRfcMiWJbaZPl5C"
        },
        "parentPage": null,
        "available": true,
        "name": "new-features",
        "menuText": "Find out about our new features",
        "order": null,
        "title": "Find out about our new features",
        "pathPrefix": null,
        "contentful_id": "1bYTwntNRfcMiWJbaZPl5C",
        "id": "1bYTwntNRfcMiWJbaZPl5C"
      }
    },
    {
      "node": {
        "sys": {
          "id": "6gMsorqHCVlifFK46dhpf3"
        },
        "parentPage": null,
        "available": true,
        "name": "universityguidance-apply",
        "menuText": "University Guidance Sign Up",
        "order": null,
        "title": "University Guidance Sign Up",
        "pathPrefix": null,
        "contentful_id": "6gMsorqHCVlifFK46dhpf3",
        "id": "6gMsorqHCVlifFK46dhpf3"
      }
    },
    {
      "node": {
        "sys": {
          "id": "5fs4mm8hsNvOBTfj7L4OaP"
        },
        "available": true,
        "name": "business-and-entrepreneurship-aoc",
        "menuText": "Business and Entrepreneurship",
        "order": 2,
        "title": "Business and Entrepreneurship AOC ",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "5fs4mm8hsNvOBTfj7L4OaP",
        "id": "5fs4mm8hsNvOBTfj7L4OaP"
      }
    },
    {
      "node": {
        "sys": {
          "id": "7GzCWBkn7zE44ESpIsA2Un"
        },
        "available": true,
        "name": "uniguidance",
        "menuText": "University guidance",
        "order": 1,
        "title": "University guidance",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "480jXQp2ZpVYOS0AunDTEE"
          },
          "available": true,
          "name": "university-preparation",
          "menuText": "University preparation",
          "order": 2,
          "title": "University Preparation (Root menu)",
          "pathPrefix": null,
          "contentful_id": "480jXQp2ZpVYOS0AunDTEE",
          "id": "480jXQp2ZpVYOS0AunDTEE",
          "parentPage": null
        },
        "contentful_id": "7GzCWBkn7zE44ESpIsA2Un",
        "id": "7GzCWBkn7zE44ESpIsA2Un"
      }
    },
    {
      "node": {
        "sys": {
          "id": "3IyjRL40jYPZL6zuZymIrs"
        },
        "available": true,
        "name": "biotechnology-and-genetics-aoc",
        "menuText": "Biotechnology and Genetics",
        "order": 1,
        "title": "Biotechnology and Genetics AOC ",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "3IyjRL40jYPZL6zuZymIrs",
        "id": "3IyjRL40jYPZL6zuZymIrs"
      }
    },
    {
      "node": {
        "sys": {
          "id": "LQ8trl4Ei1fRas8l6DvLD"
        },
        "available": true,
        "name": "computer-science-aoc",
        "menuText": "Computer Science",
        "order": 3,
        "title": "Computer Science AOC ",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "LQ8trl4Ei1fRas8l6DvLD",
        "id": "LQ8trl4Ei1fRas8l6DvLD"
      }
    },
    {
      "node": {
        "sys": {
          "id": "60R5cDCT6fr1oKqhPmnC24"
        },
        "available": true,
        "name": "economics-aoc",
        "menuText": "Economics",
        "order": 4,
        "title": "Economics AOC ",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "60R5cDCT6fr1oKqhPmnC24",
        "id": "60R5cDCT6fr1oKqhPmnC24"
      }
    },
    {
      "node": {
        "sys": {
          "id": "121D0HJpbzBCTyZ0JqdqL6"
        },
        "available": true,
        "name": "english-literature-and-creative-writing-aoc",
        "menuText": "English Literature and Creative Writing",
        "order": 5,
        "title": "English Literature and Creative Writing AOC ",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "121D0HJpbzBCTyZ0JqdqL6",
        "id": "121D0HJpbzBCTyZ0JqdqL6"
      }
    },
    {
      "node": {
        "sys": {
          "id": "2Y72nFFkMEH7lkZzTw6WzP"
        },
        "available": true,
        "name": "international-relations-and-politics-aoc",
        "menuText": "International Relations and Politics",
        "order": 6,
        "title": "International Relations and Politics AOC ",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "2Y72nFFkMEH7lkZzTw6WzP",
        "id": "2Y72nFFkMEH7lkZzTw6WzP"
      }
    },
    {
      "node": {
        "sys": {
          "id": "7hIuPpfzDBl1uc2SIFZge8"
        },
        "available": true,
        "name": "law-aoc",
        "menuText": "Law",
        "order": 7,
        "title": "Law AOC",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "7hIuPpfzDBl1uc2SIFZge8",
        "id": "7hIuPpfzDBl1uc2SIFZge8"
      }
    },
    {
      "node": {
        "sys": {
          "id": "3GMJUeOuBy88nO7Ysxr0hS"
        },
        "available": true,
        "name": "mathematics-aoc",
        "menuText": "Mathematics",
        "order": 8,
        "title": "Mathematics AOC ",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "3GMJUeOuBy88nO7Ysxr0hS",
        "id": "3GMJUeOuBy88nO7Ysxr0hS"
      }
    },
    {
      "node": {
        "sys": {
          "id": "SP8iGpGZnjeqmk2zE4piq"
        },
        "available": true,
        "name": "medicine-aoc",
        "menuText": "Medicine",
        "order": 9,
        "title": "Medicine AOC ",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "SP8iGpGZnjeqmk2zE4piq",
        "id": "SP8iGpGZnjeqmk2zE4piq"
      }
    },
    {
      "node": {
        "sys": {
          "id": "29BJf45i8sjcx8Q6ZEo19a"
        },
        "available": true,
        "name": "physics-aoc",
        "menuText": "Physics",
        "order": 10,
        "title": "Physics AOC ",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "29BJf45i8sjcx8Q6ZEo19a",
        "id": "29BJf45i8sjcx8Q6ZEo19a"
      }
    },
    {
      "node": {
        "sys": {
          "id": "1Od9ALmU3NqTOVIf1kSPRs"
        },
        "available": true,
        "name": "psychology-aoc",
        "menuText": "Psychology",
        "order": 11,
        "title": "Psychology AOC",
        "pathPrefix": "aoc",
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "1Od9ALmU3NqTOVIf1kSPRs",
        "id": "1Od9ALmU3NqTOVIf1kSPRs"
      }
    },
    {
      "node": {
        "sys": {
          "id": "6UnBXhexPZOHk4rk4eYFWi"
        },
        "available": true,
        "name": "aoc",
        "menuText": "Academic support",
        "order": 1,
        "title": "Academic support",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "6p8LoO6tYJteeP36si4Y7u"
          },
          "available": true,
          "name": "acoc",
          "menuText": "Subject taster courses",
          "order": 2,
          "title": "Academic support placeholder",
          "pathPrefix": null,
          "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
          "id": "6p8LoO6tYJteeP36si4Y7u",
          "parentPage": null
        },
        "contentful_id": "6UnBXhexPZOHk4rk4eYFWi",
        "id": "6UnBXhexPZOHk4rk4eYFWi"
      }
    },
    {
      "node": {
        "sys": {
          "id": "1JIPJNJbFUGPqe6Bv8sjfe"
        },
        "available": true,
        "name": "career-guidance",
        "menuText": "Career guidance",
        "order": 4,
        "title": "Career guidance ",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "480jXQp2ZpVYOS0AunDTEE"
          },
          "available": true,
          "name": "university-preparation",
          "menuText": "University preparation",
          "order": 2,
          "title": "University Preparation (Root menu)",
          "pathPrefix": null,
          "contentful_id": "480jXQp2ZpVYOS0AunDTEE",
          "id": "480jXQp2ZpVYOS0AunDTEE",
          "parentPage": null
        },
        "contentful_id": "1JIPJNJbFUGPqe6Bv8sjfe",
        "id": "1JIPJNJbFUGPqe6Bv8sjfe"
      }
    },
    {
      "node": {
        "sys": {
          "id": "6p8LoO6tYJteeP36si4Y7u"
        },
        "available": true,
        "name": "acoc",
        "menuText": "Subject taster courses",
        "order": 2,
        "title": "Academic support placeholder",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "480jXQp2ZpVYOS0AunDTEE"
          },
          "available": true,
          "name": "university-preparation",
          "menuText": "University preparation",
          "order": 2,
          "title": "University Preparation (Root menu)",
          "pathPrefix": null,
          "contentful_id": "480jXQp2ZpVYOS0AunDTEE",
          "id": "480jXQp2ZpVYOS0AunDTEE",
          "parentPage": null
        },
        "contentful_id": "6p8LoO6tYJteeP36si4Y7u",
        "id": "6p8LoO6tYJteeP36si4Y7u"
      }
    },
    {
      "node": {
        "sys": {
          "id": "2eT7UDH7TgpSCjpTN1Mm2D"
        },
        "available": true,
        "name": "university-preparation-program",
        "menuText": "University Preparation Program",
        "order": 2,
        "title": "University Preparation Program",
        "pathPrefix": "courses",
        "parentPage": {
          "sys": {
            "id": "78C9rMCKFhQWMw4rV6pBvo"
          },
          "available": true,
          "name": "courses",
          "menuText": "Courses",
          "order": 3,
          "title": "Courses",
          "pathPrefix": null,
          "contentful_id": "78C9rMCKFhQWMw4rV6pBvo",
          "id": "78C9rMCKFhQWMw4rV6pBvo",
          "parentPage": null
        },
        "contentful_id": "2eT7UDH7TgpSCjpTN1Mm2D",
        "id": "2eT7UDH7TgpSCjpTN1Mm2D"
      }
    },
    {
      "node": {
        "sys": {
          "id": "5gpqPAPavLb7qtzUmO2S91"
        },
        "parentPage": null,
        "available": true,
        "name": "oxbridge-webinar-recording",
        "menuText": "Oxbridge Webinar Recording",
        "order": null,
        "title": "Oxbridge Webinar Recording",
        "pathPrefix": null,
        "contentful_id": "5gpqPAPavLb7qtzUmO2S91",
        "id": "5gpqPAPavLb7qtzUmO2S91"
      }
    },
    {
      "node": {
        "sys": {
          "id": "4NbtFi4GRftGGHu8OojmT9"
        },
        "available": true,
        "name": "programs-and-packages",
        "menuText": "Programs and packages",
        "order": 1,
        "title": "Programs and packages",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "5LWO5JBWJI13L6RFPkiuHI"
          },
          "available": true,
          "name": "schools",
          "menuText": "Melio for schools",
          "order": 4,
          "title": "melio for schools",
          "pathPrefix": null,
          "contentful_id": "5LWO5JBWJI13L6RFPkiuHI",
          "id": "5LWO5JBWJI13L6RFPkiuHI",
          "parentPage": null
        },
        "contentful_id": "4NbtFi4GRftGGHu8OojmT9",
        "id": "4NbtFi4GRftGGHu8OojmT9"
      }
    },
    {
      "node": {
        "sys": {
          "id": "5LWO5JBWJI13L6RFPkiuHI"
        },
        "parentPage": null,
        "available": true,
        "name": "schools",
        "menuText": "Melio for schools",
        "order": 4,
        "title": "melio for schools",
        "pathPrefix": null,
        "contentful_id": "5LWO5JBWJI13L6RFPkiuHI",
        "id": "5LWO5JBWJI13L6RFPkiuHI"
      }
    },
    {
      "node": {
        "sys": {
          "id": "6LHqKMMkS036uS7uD5dP6h"
        },
        "available": true,
        "name": "tutors",
        "menuText": "Tutors",
        "order": 2,
        "title": "Tutors All",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "3pk6v4cfcMxChmlAmqsRvr"
          },
          "available": true,
          "name": "about",
          "menuText": "About",
          "order": 5,
          "title": "About Melio",
          "pathPrefix": null,
          "contentful_id": "3pk6v4cfcMxChmlAmqsRvr",
          "id": "3pk6v4cfcMxChmlAmqsRvr",
          "parentPage": null
        },
        "contentful_id": "6LHqKMMkS036uS7uD5dP6h",
        "id": "6LHqKMMkS036uS7uD5dP6h"
      }
    },
    {
      "node": {
        "sys": {
          "id": "2mxEinTMBYE4OtKlBgLp8u"
        },
        "parentPage": null,
        "available": true,
        "name": "blog",
        "menuText": "Get advice",
        "order": 5,
        "title": "Blog",
        "pathPrefix": null,
        "contentful_id": "2mxEinTMBYE4OtKlBgLp8u",
        "id": "2mxEinTMBYE4OtKlBgLp8u"
      }
    },
    {
      "node": {
        "sys": {
          "id": "5TWoDi16jOOi6YVDERecDS"
        },
        "parentPage": null,
        "available": true,
        "name": "help",
        "menuText": "Help",
        "order": null,
        "title": "Help",
        "pathPrefix": null,
        "contentful_id": "5TWoDi16jOOi6YVDERecDS",
        "id": "5TWoDi16jOOi6YVDERecDS"
      }
    },
    {
      "node": {
        "sys": {
          "id": "7svCBA75Me7LwdXjCsKMJC"
        },
        "available": true,
        "name": "how-it-works",
        "menuText": "How it works",
        "order": 2,
        "title": "How it works",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "5LWO5JBWJI13L6RFPkiuHI"
          },
          "available": true,
          "name": "schools",
          "menuText": "Melio for schools",
          "order": 4,
          "title": "melio for schools",
          "pathPrefix": null,
          "contentful_id": "5LWO5JBWJI13L6RFPkiuHI",
          "id": "5LWO5JBWJI13L6RFPkiuHI",
          "parentPage": null
        },
        "contentful_id": "7svCBA75Me7LwdXjCsKMJC",
        "id": "7svCBA75Me7LwdXjCsKMJC"
      }
    },
    {
      "node": {
        "sys": {
          "id": "3pk6v4cfcMxChmlAmqsRvr"
        },
        "parentPage": null,
        "available": true,
        "name": "about",
        "menuText": "About",
        "order": 5,
        "title": "About Melio",
        "pathPrefix": null,
        "contentful_id": "3pk6v4cfcMxChmlAmqsRvr",
        "id": "3pk6v4cfcMxChmlAmqsRvr"
      }
    },
    {
      "node": {
        "sys": {
          "id": "4tbQ50DrlyOpEqHTYWZO8V"
        },
        "parentPage": null,
        "available": true,
        "name": "where-to-study",
        "menuText": "Where to study",
        "order": 3,
        "title": "Where to study (Root menu)",
        "pathPrefix": null,
        "contentful_id": "4tbQ50DrlyOpEqHTYWZO8V",
        "id": "4tbQ50DrlyOpEqHTYWZO8V"
      }
    },
    {
      "node": {
        "sys": {
          "id": "480jXQp2ZpVYOS0AunDTEE"
        },
        "parentPage": null,
        "available": true,
        "name": "university-preparation",
        "menuText": "University preparation",
        "order": 2,
        "title": "University Preparation (Root menu)",
        "pathPrefix": null,
        "contentful_id": "480jXQp2ZpVYOS0AunDTEE",
        "id": "480jXQp2ZpVYOS0AunDTEE"
      }
    },
    {
      "node": {
        "sys": {
          "id": "1l91xSgOT3zh0ef7QMDoN2"
        },
        "parentPage": null,
        "available": true,
        "name": "careers",
        "menuText": "Careers",
        "order": null,
        "title": "Careers",
        "pathPrefix": null,
        "contentful_id": "1l91xSgOT3zh0ef7QMDoN2",
        "id": "1l91xSgOT3zh0ef7QMDoN2"
      }
    },
    {
      "node": {
        "sys": {
          "id": "78C9rMCKFhQWMw4rV6pBvo"
        },
        "parentPage": null,
        "available": true,
        "name": "courses",
        "menuText": "Courses",
        "order": 3,
        "title": "Courses",
        "pathPrefix": null,
        "contentful_id": "78C9rMCKFhQWMw4rV6pBvo",
        "id": "78C9rMCKFhQWMw4rV6pBvo"
      }
    },
    {
      "node": {
        "sys": {
          "id": "6k6O6XhbuonvPxl35k3uiT"
        },
        "parentPage": null,
        "available": true,
        "name": "upcoming-course-dates",
        "menuText": "Course dates",
        "order": null,
        "title": "Upcoming course dates",
        "pathPrefix": null,
        "contentful_id": "6k6O6XhbuonvPxl35k3uiT",
        "id": "6k6O6XhbuonvPxl35k3uiT"
      }
    },
    {
      "node": {
        "sys": {
          "id": "2AsqrghAsRkNyOn7AVwHkg"
        },
        "parentPage": null,
        "available": true,
        "name": "webinar-signup",
        "menuText": "Webinar sign up",
        "order": null,
        "title": "Webinar sign up",
        "pathPrefix": null,
        "contentful_id": "2AsqrghAsRkNyOn7AVwHkg",
        "id": "2AsqrghAsRkNyOn7AVwHkg"
      }
    },
    {
      "node": {
        "sys": {
          "id": "42WeGCNViWuGhqzEOmZEU6"
        },
        "parentPage": null,
        "available": true,
        "name": "quiz",
        "menuText": "Quiz",
        "order": null,
        "title": "Quiz page ",
        "pathPrefix": null,
        "contentful_id": "42WeGCNViWuGhqzEOmZEU6",
        "id": "42WeGCNViWuGhqzEOmZEU6"
      }
    },
    {
      "node": {
        "sys": {
          "id": "5JT4cwRTcrlMUr8iOlxzAi"
        },
        "parentPage": null,
        "available": true,
        "name": "university-webinars",
        "menuText": "University Webinars",
        "order": null,
        "title": "University Webinars",
        "pathPrefix": null,
        "contentful_id": "5JT4cwRTcrlMUr8iOlxzAi",
        "id": "5JT4cwRTcrlMUr8iOlxzAi"
      }
    },
    {
      "node": {
        "sys": {
          "id": "1tFMCgpiyN85GIVX5iYVdb"
        },
        "available": true,
        "name": "academic-online-courses",
        "menuText": "Academic Online Courses",
        "order": 1,
        "title": "AOC",
        "pathPrefix": null,
        "parentPage": {
          "sys": {
            "id": "78C9rMCKFhQWMw4rV6pBvo"
          },
          "available": true,
          "name": "courses",
          "menuText": "Courses",
          "order": 3,
          "title": "Courses",
          "pathPrefix": null,
          "contentful_id": "78C9rMCKFhQWMw4rV6pBvo",
          "id": "78C9rMCKFhQWMw4rV6pBvo",
          "parentPage": null
        },
        "contentful_id": "1tFMCgpiyN85GIVX5iYVdb",
        "id": "1tFMCgpiyN85GIVX5iYVdb"
      }
    },
    {
      "node": {
        "sys": {
          "id": "3THmzTCsd7R2XEG6Fpi6fl"
        },
        "parentPage": null,
        "available": true,
        "name": "bcourses",
        "menuText": "Courses",
        "order": null,
        "title": "Bethan Courses 2",
        "pathPrefix": null,
        "contentful_id": "3THmzTCsd7R2XEG6Fpi6fl",
        "id": "3THmzTCsd7R2XEG6Fpi6fl"
      }
    },
    {
      "node": {
        "sys": {
          "id": "V0cifPQfZAQFySjZN3Pbt"
        },
        "parentPage": null,
        "available": true,
        "name": "reviews",
        "menuText": "Reviews",
        "order": 5,
        "title": "Reviews",
        "pathPrefix": null,
        "contentful_id": "V0cifPQfZAQFySjZN3Pbt",
        "id": "V0cifPQfZAQFySjZN3Pbt"
      }
    },
    {
      "node": {
        "sys": {
          "id": "5Hn0bsdCxu71aSxCokvM3t"
        },
        "parentPage": null,
        "available": true,
        "name": "faq",
        "menuText": "FAQs",
        "order": null,
        "title": "Frequently Asked Questions",
        "pathPrefix": null,
        "contentful_id": "5Hn0bsdCxu71aSxCokvM3t",
        "id": "5Hn0bsdCxu71aSxCokvM3t"
      }
    }
  ]
};
  const blogId = "3P6sZT0snty8ryiHx7HhS2";
  const { data } = props;
  const blogData = data && data.contentfulPost
  return (
    <Layout {...context}>
      <Header extraData={extraData} />
      <BlogPost data={blogData} blogId={blogId}/>
      <Footer {...props} navigate={navigate} />
      
        <ContentfulComponentSeo {...props} extraData={extraData} componentId="contentfulComponentSeo0" />
      
    </Layout>
  )
}
export default Post;